// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment ={
  production: false,
  platform: 'desktop',
  api: {
    url: 'https://app-v2.guineo-dev.com/',
    get:'_get',
    post:'_post',
		put:'_put',
		delete:'_delete'
  }
};

//PLATFORM:
//android
//ios
//desktop

// https://guineo.co/apirest/v2/
// https://guineo.co/api-dev/
// https://guineo.co/apirest/beta/v201/ // Api Pruebas server
// http://localhost:8888/guineo/guineoApp/ // Api localhost
// http://apirestpruebav2.guineo.co/ //Api pruebas Hector
// https://api.guineo.co/v2/ http://localhost:8888/v2/ https://server-guineo.herokuapp.com/v2/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

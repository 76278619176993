import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Http,Headers,RequestOptions  } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class PrLoginService {

  _token: string = null
  _user: any = {}

 constructor(
   private http: Http,
   private httpClient: HttpClient,
  ){}
 
 login(datos): any{
   var variable_2 = JSON.stringify(datos);
   var url = environment.api.url+'userdata/userlogin'+environment.api.post;

   this.http.post(url,variable_2).map(res => {     
     return res;

   }, error => { });
  
 }

// 
loginTest(datos){
  var variable_2 = JSON.stringify(datos);
  var url = environment.api.url+'userdata/userlogin'+environment.api.post;

  return new Promise( resolve => {
    this.httpClient.post(`${url}`, variable_2).subscribe(async (resp:any) => {

      if(!resp){
        return resolve(false)
      }  

      localStorage.setItem("token", resp.data.token);
      localStorage.setItem("user", JSON.stringify(resp.data))      
      resolve(true)
      
    }, error => {
      resolve(false)

    })
  })
}

loginGoogle(datos){
  var variable_2 = JSON.stringify(datos);
  var url = environment.api.url+'userdata/userglogin'+environment.api.post;

  return new Promise(resolve => {
    this.httpClient.post(`${url}`, variable_2).subscribe(async (resp:any) => {
      
      if(!resp){
        return resolve(false)
      }  

      localStorage.setItem("token", resp.data.token);
      localStorage.setItem("user", JSON.stringify(resp.data))      
      resolve(true)
      
    }, error => {    
      resolve(false)
    })
  })
}

loginApple(datos){
  var variable_2 = JSON.stringify(datos);
  var url = environment.api.url+'userdata/userApplelogin'+environment.api.post;

  return new Promise(resolve => {
    this.httpClient.post(`${url}`, variable_2).subscribe(async (resp:any) => {
      
      if(!resp){
        return resolve(false)
      }  

      localStorage.setItem("token", resp.data.token);
      localStorage.setItem("user", JSON.stringify(resp.data))      
      resolve(true)
      
    }, error => {    
      resolve(false)
    })
  })
}

registroGoogle(datos){
  var variable_2=JSON.stringify(datos);
 	var url = environment.api.url+'userdata/userglogin'+environment.api.post;
  var response = this.http.post(url,variable_2).map(res => res.json());
  return response;
 }

 async changeToken(){
   this._token = await localStorage.getItem("token") || null
 }

 async validateToken(): Promise<boolean> {
   await this.changeToken()

   if(!this._token){
    return Promise.resolve(false)
   }else{
     return Promise.resolve(true)
   }

 }


 registro(datos){
  var variable_2=JSON.stringify(datos);
 	var url = environment.api.url+'userdata/user'+environment.api.post;
  var response = this.http.post(url,variable_2).map(res => res.json());
  return response;
 }

 get_datos_usuario(datos){
  var variable_2=JSON.stringify(datos);
  var url = environment.api.url+'userdata/get_datos_usuario';  
  
  var response = this.http.post(url,datos).map(res => {
    return res.json()

  }, err => { });

  return response;
 }

 get_datos_creditCards_usuario(datos){
    var variable_2=JSON.stringify(datos);
    var url = environment.api.url+'userdata/get_datos_creditCards';  
    
    var response = this.http.post(url,variable_2).map(res => {
    return res.json()
  });

  return response;
 }

 olvido_password(datos){
  var variable_2=JSON.stringify(datos);
 	var url = environment.api.url+'userdata/userforgotpass'+environment.api.post;

  var response = this.http.post(url,variable_2)
        .map(res => {
            return res.json();
          });
  return response;
 }
user_order(datos){
   var variable_2=JSON.stringify(datos);
   var url = environment.api.url+'userdata/user_order'+environment.api.post;
   var response = this.http.post(url,variable_2).map(res => res.json());
   return response;
 }

actualizar_usuario(datos){
  var variable_2 = JSON.stringify(datos);
  var url = environment.api.url+'userdata/user'+environment.api.put;
  var response = this.http.post(url, variable_2).map(res =>res.json());  
  return response;
 }

 update_password(datos){
  var variable_2 = JSON.stringify(datos);
  var url = environment.api.url+'userdata/user_password'+environment.api.put;
  var response = this.http.post(url, variable_2).map(res => res.json());   
  return response;
 }

}


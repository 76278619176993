import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpModule} from '@angular/http';
import { PathLocationStrategy, registerLocaleData } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpService } from './http.service';
// import local
import localeCo from '@angular/common/locales/es-CO';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
// register locale

import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { SignInWithApple } from "@ionic-native/sign-in-with-apple/ngx";

registerLocaleData(localeCo, 'es')
var firebaseConfig = {
  apiKey: "AIzaSyCfuBRcvpTe6FgXLIpT_7PnmYHxVVhfki0",
    authDomain: "fir-guineoapp.firebaseapp.com",
    databaseURL: "https://fir-guineoapp.firebaseio.com",
    projectId: "firebase-guineoapp",
    storageBucket: "firebase-guineoapp.appspot.com",
    messagingSenderId: "867604366022",
    appId: "1:867604366022:web:64782d877095ef245acdc7"
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    IonicModule.forRoot({ mode: 'ios' }),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    InAppBrowser,
    OneSignal,
    SignInWithApple,
    {provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: "es-CO" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CityGuard implements CanActivate, CanActivateChild {

  constructor(private navCtrl: NavController) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!!localStorage.getItem('city_id')) {
      return true;
    }
    this.navCtrl.navigateRoot(['/city'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!!localStorage.getItem('city_id')) {
      return true;
    }
    this.navCtrl.navigateRoot(['/city'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}

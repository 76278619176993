import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CityGuard } from './guards/city.guard';

const routes: Routes = [
  { path: '', redirectTo: 'menu/products', pathMatch: 'full' }, 
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
  },
  {
    path: 'auth/:type',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
  },
  {
    path: 'city',
    loadChildren: () => import('./pages/city/city.module').then(m => m.CityPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuPageModule),
    canActivate: [CityGuard],
    canActivateChild: [CityGuard]
  },
  {
    path: 'card-list',
    loadChildren: () => import('./pages/card-list/card-list.module').then( m => m.CardListPageModule)
  },
  {
    path: 'payment-pse',
    loadChildren: () => import('./pages/payment-pse/payment-pse.module').then( m => m.PaymentPsePageModule)
  },
  {
    path: 'back-pse',
    loadChildren: () => import('./pages/back-pse/back-pse.module').then( m => m.BackPsePageModule)
  },
  {
    path: 'politics/:type',
    loadChildren: () => import('./pages/politics/politics.module').then(m => m.PoliticsModule)
  },
  {
    path: 'terms/:type',
    loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

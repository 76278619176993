import { Injectable } from '@angular/core';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Platform, AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  // _userId: any

  userId: String

  constructor(
    private oneSignal:OneSignal,
    private platform: Platform,
    public alertController: AlertController
  ) { }


  notificaciones(){
    this.oneSignal.startInit('468c2f8c-b76c-4429-b486-346c840f78ae', '805416819905'); //(appId_onesignal,googleProjectNumber)

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);

    this.oneSignal.handleNotificationOpened().subscribe(jsonData=>{
      this.presentAlert(jsonData.notification.payload.title,jsonData.notification.payload.body);
    });

    this.oneSignal.endInit();

    this.oneSignal.getIds().then((id)=>{
      this.userId = id.userId;
    })
  }

  async presentAlert(titulo,sub_titulo){
    const alert = await this.alertController.create({
      header: titulo,
      subHeader: '',
      message: sub_titulo,
      buttons: ['OK']
    });
    await alert.present();
  }


  // async changeUserId(){
  //   if(this.platform.is('android') || this.platform.is('ios')){
  //     this._userId = await this.oneSignal.getIds().then((id)=>{})
  //   }else{
  //     this._userId = "";
  //   }
  // }


  // getUserId(){
  //   return this._userId
  // }
}

import { Component } from '@angular/core';
import { Platform,ModalController,AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import {PrLoginService} from './providers/pr-login/pr-login.service';
import { NotificationsService } from './services/notifications.service';
import { environment } from '../environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  userId:String;


  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private modalCtrl: ModalController, 
    public oneSignal:OneSignal,
    private notificationsService: NotificationsService,
    public pr_login:PrLoginService, 
    public alertController: AlertController)
  {
    this.initializeApp();
  }

  initializeApp(){
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      localStorage.removeItem('viene_de');
      if(environment.platform != 'desktop'){
        this.notificationsService.notificaciones()
      }
    });
  }

}
